<template>
  <div class="home">
    <Header :siteInfo="websiteInfo" :logo="logo" v-if="headerState" />
    <!-- <template v-if="keepLiveState">
      <keep-alive>
        <router-view />
      </keep-alive>
    </template>
    <template v-else> -->
      <router-view />
    <!-- </template> -->
    
    <Bottom :bottomInfo="bottomInfo"  v-if="bottomInfo&&footerState"/>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import Header from "@/components/pc/header.vue";
import Bottom from "@/components/pc/bottom.vue";

export default {
  name: "Home",
  components: {
    Header,
    Bottom,
  },
  data() {
    return {
      headerMenu: [], //头部菜单
      bottomInfo: null, //底部菜单
      logo: "",
      setting: {
        keywords: "",
        description: "",
      },
      websiteInfo: '',
      keepLiveState:false,
      headerState: true,
      footerState: true,
    };
  },
  watch:{
    $route:{
      handler(newVal){
        this.keepLiveState = newVal.meta.keepLiveState?true:false
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        const { headerIsShow, footerIsShow } = newVal.meta;
        if(newVal.meta.titleText=='考试'){
          if(newVal.params.type =='2'){
            this.headerState = headerIsShow ? false : true;
            this.footerState = footerIsShow ? false : true;
          }else{
            this.headerState = true
            this.footerState = true
          }
        }else{
          this.headerState = headerIsShow ? false : true;
          this.footerState = footerIsShow ? false : true;
        }
        
       
      },
      immediate:true,
    }
  },
  metaInfo() {
    return {
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },
  created() {
    this.getWebsiteInfo();
  },
  mounted() {
    this.getWebsiteInfo(); //获取网站信息
  },
  methods: {
    ...mapMutations(["SetWebsiteState"]), //登录弹窗
    getMeat() {
      this.setting.keywords = this.bottomInfo.keyword;
      this.setting.description = this.bottomInfo.desc;
    },
    //获取网站信息
    getWebsiteInfo() {
      this.$axios.post(`/v1/websiteInfo`, { is_pc: 1 }).then((res) => {
        localStorage.setItem("siteInfo", JSON.stringify(res.data.site_info));
        // this.headerMenu = res.data.navigation;
        this.websiteInfo = res.data;
        this.bottomInfo = res.data.site_info;
        this.logo = res.data.site_info.site_logo;
        document.getElementById("linIcon").href = res.data.site_info.site_icon;
        document.getElementById("titleName").innerHTML = res.data.site_info.name;
        this.getMeat();
      });

      this.$axios.post(`/v1/home/getStatus`, { host: window.location.host }).then((res) => {
        if (res.code == 0) {
          this.SetWebsiteState(res.data);
        }
      });
    },
  },
};
</script>

<style lang="scss">
//pc
//form表单样式
.from-box {
  margin: 0 auto;
  .input-box {
    box-sizing: border-box;
    width: 100%;
    padding-left: 80px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #e5e5e5;
    position: relative;
    margin: 10px 0;
    input {
      width: 100%;
    }
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 15px;
      background-color: #d8d8d8;
      position: absolute;
      top: 50%;
      left: 59px;
      margin-top: -7.5px;
    }
    &.enterprise-name {
      background: url(~@/assets/contact-ico.png) no-repeat 18px center;
      background-size: 28px auto;
    }
    &.user-name {
      background: url(~@/assets/user-ico.png) no-repeat 20px center;
      background-size: 24px auto;
    }
    &.danwei {
      background: url(~@/assets/danwei.png) no-repeat 20px center;
      background-size: 24px auto;
    }
    &.zhiwu {
      background: url(~@/assets/zhiwuguanli.png) no-repeat 20px center;
      background-size: 20px auto;
    }
    &.user-phone {
      background: url(~@/assets/phone-grey-ico.png) no-repeat 18px center;
      background-size: 24px auto;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    &.ver-code {
      padding-right: 100px;
      background: url(~@/assets/code-ico.png) no-repeat 18px center;
      background-size: 28px auto;
    }
    .get-ver-code {
      position: absolute;
      right: 20px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #254ed4;
      cursor: pointer;
      &.get {
        color: #999;
        cursor: default;
      }
    }
    &.password {
      padding-right: 60px;
      background: url(~@/assets/lock-close-ico.png) no-repeat 20px center;
      background-size: 24px auto;
      input::-ms-clear {
        display: none;
      }
      input::-ms-reveal {
        display: none;
      }
      .show-pass {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -14px;
        cursor: pointer;
        &.open {
          background: url(~@/assets/eye-open-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
        &.close {
          background: url(~@/assets/eye-close-ico.png) no-repeat 0 center;
          background-size: 100% auto;
        }
      }
    }
    .input-text {
      padding: 13px 0 12px;
    }
  }
  .tip-err {
    font-size: 12px;
    color: #cd1b14;
    // position: absolute;
    // left: 0;
    // bottom: -20px;
    //margin-bottom: 24px;
  }
}
//下拉框
.drop-down-box {
  height: 22px;
  padding-right: 25px;
  background: url(~@/assets/arrows.png) no-repeat right center;
  background-size: 22px;
  position: relative;
  .drop-down-text {
    height: 22px;
    line-height: 22px;
    font-size: 14px;
    color: #000c28;
    cursor: pointer;
  }
  .drop-down-list {
    display: none;
    padding: 10px 0;
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    position: absolute;
    top: 30px;
  }
  .drop-down-option {
    padding: 8px 28px 8px 44px;
    white-space: nowrap;
    font-size: 12px;
    color: #666;
    &:hover {
      box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.14);
      cursor: pointer;
    }
    &.active {
      background: url(~@/assets/tick-ico.png) no-repeat 20px center;
      background-size: 20px auto;
    }
  }
}
//直播展示模块
.live-list-content {
  width: 100%;
  height: 396px;
  .img-show {
    width: 820px;
    height: 396px;
    border-radius: 6px 0 0 6px;
    float: left;
    background-color: #ccc;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .live-list {
    width: 380px;
    height: 396px;
    float: left;
    overflow-y: auto;
  }
  .live-info {
    padding: 19px 20px 24px 15px;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 2px;
      height: 50px;
      background-color: rgba(0, 0, 0, 0.06);
      position: absolute;
      bottom: 0;
      left: 29px;
    }
    &.active {
      background: #f5f5f5;
    }
  }
  .live-now {
    height: 30px;
    line-height: 30px;
    padding-left: 40px;
    font-size: 16px;
    font-weight: 800;
    color: #f5a623;
    background: url(~@/assets/live-now-ico.png) no-repeat;
    background-size: 30px;
    background-position: left;
    margin-bottom: 7px;
  }
  .live-date {
    height: 20px;
    line-height: 20px;
    padding-left: 34px;
    background: url(~@/assets/clock-ico.png) no-repeat;
    background-size: 20px;
    background-position: 6px center;
    font-size: 12px;
    font-weight: 500;
    color: #999999;
  }
  .live-title {
    padding-left: 40px;
    font-size: 16px;
    color: #333;
    margin-bottom: 12px;
  }
  .live-lecturer {
    padding-left: 40px;
    font-size: 12px;
    color: #666;
  }
  .live-btn {
    width: 94px;
    height: 34px;
    box-shadow: 0px 2px 4px 0px rgba(101, 67, 11, 0.2);
    border-radius: 6px;
    position: absolute;
    right: 20px;
    bottom: 14px;
    cursor: pointer;
    &.into {
      background: #ffc30b;
      color: #333;
    }
    &.subscribe {
      background: #254ed4;
      color: #fff;
    }
  }
}
//标签
.marks {
  overflow: hidden;
  margin-bottom: 14px;
  .mark {
    padding: 0 8px;
    height: 18px;
    line-height: 18px;
    background: rgba(37, 78, 212, 0.14);
    border-radius: 10px 2px 10px 2px;
    font-size: 12px;
    color: #254ed4;
    text-align: center;
    float: left;
    margin-right: 12px;
  }
}
//筛选条件
.filtrate-content {
  width: 1200px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 27px;
  .filtrate-list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 24px 0 14px;
    &.classes {
      padding-bottom: 24px;
      border-bottom: 1px solid #e5e5e5;
    }
    .filtrate-title {
      margin: 1px 10px 0 0;
      white-space: nowrap;
    }
    .filtrate-option {
      font-size: 14px;
      color: #666;
      margin: 0 48px 10px 0;
      white-space: nowrap;
      float: left;
      cursor: pointer;
      &.active {
        color: #254ed4;
      }
    }
  }
  .more {
    height: 19.88px;
    padding-right: 22px;
    font-size: 14px;
    color: #999;
    position: absolute;
    right: 0;
    bottom: 14px;
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      width: 19.88px;
      height: 19.88px;
      background: url(~@/assets/arrows-right.png) no-repeat;
      background-size: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transform: rotate(90deg);
    }
  }
}
//表格样式
.my-table {
  width: 100%;
  height: auto;
  border-radius: 1px;
  border: 1px solid #e5e5e5;
  .table-th,
  .table-tr {
    height: 78px;
    display: flex;
    align-items: center;
    background-color: #fff;
    .table-td {
      font-size: 14px;
      color: #666;
    }
  }
  .table-th {
    height: 64px;
    background-color: #f5f5f5;
    .table-td {
      color: #333;
    }
  }
  .table-td {
    text-align: center;
  }
}
//文本框
.form-text {
  box-sizing: border-box;
  padding: 0 14px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #e5e5e5;
}
.form-input {
  width: 100%;
  padding: 8px 0 9px;
  font-size: 14px;
  color: #333;
}
//表单错误提示
.err-tip {
  box-sizing: border-box;
  width: 100%;
  font-size: 12px;
  color: #cd1b14;
  margin-top: 10px;
}
.button {
  padding: 7px 12px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  &.blue {
    background: #254ed4;
    color: #fff;
  }
  &.gery {
    background: #e5e5e5;
    color: #666;
  }
}
//复选框
.check-box {
  width: 14px;
  height: 14px;
  border: 1px solid #d8d8d8;
  border-radius: 50%;
  &.active {
    border-color: #254ed4;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      content: "";
      display: block;
      width: 70%;
      height: 70%;
      border: none;
      border-radius: 50%;
      background-color: #254ed4;
    }
  }
}
</style>
