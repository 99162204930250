import Vue from 'vue'
import VueRouter from 'vue-router'
import PCHome from '@/views/pc/home.vue'
import MHome from '@/m/home.vue'

import { isPC as IsPC } from '@/utils/util'
import { KeepAlive } from 'vue'

Vue.use(VueRouter)

const routes_pc = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'PCHome',
    component: PCHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        // 本地修改黔南州的首页
        // component: () => import('../views/pc/indexQh.vue'),
        // 线上部署放开 不同的分站首页展示不同
        component: () => {
          if(window.location.host == 'localhost:8081'){
            return import('@/views/pc/indexQnz.vue')
          }
          if(
            window.location.host=='cxgzspx.kskstudy.com' || 
            window.location.host=='ymjg.kskstudy.com' || 
            window.location.host=='wxbz.kskstudy.com' ||
            window.location.host=='scglz.kskstudy.com'
          ){
            return import('@/views/pc/newIndex.vue')
          } else if(window.location.host=='qnzgjxy.kskstudy.com'){
            return import('@/views/pc/indexQnz.vue')
          } else if(window.location.host=='aqgl.kskstudy.com'){
            return import('@/views/pc/indexAqgl.vue')
          }else if(window.location.host=='qhgjxy.kskstudy.com'){
            return import('@/views/pc/indexQh.vue')
          } else {
            return import('@/views/pc/index.vue')
          }
        },
        meta:{
          keepLiveState:true,
        }
      },
      //空白页面，用于获取url参数
      {
        path: '/gzLoginValidate',
        name: 'gzLoginValidate',
        component: () => import(/* webpackChunkName: "about" */ '../views/pc/gzLoginValidate.vue')
      },
      //欢迎注册
      {
        path: '/welcomeRegistered',
        name: 'WelcomeRegistered',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/pc/welcomeRegistered.vue')
      },
      //直播页面
      {
        path: '/live/:no/:live_id',
        name: 'Live',
        component: () => import(/* webpackChunkName: "about" */ '../views/pc/live.vue')
      },
      //学员须知-劳模学院分站
      {
        path: '/studentNotice',
        name: 'studentNotice',
        component: () => import(/* webpackChunkName: "about" */ '../views/pc/studentNotice.vue')
      },
         // 活动
         {
          path: '/activity/list',
          name: 'activity/list',
          component: () => import('../views/pc/activity/list.vue'),
        },{
          path: '/activity/detail',
          name: 'activity/detail',
          component: () => import('../views/pc/activity/detail.vue'),
        },
      //注册
      {
        path: '/registered/:type',
        name: 'Registered',
        component: () => import('../views/pc/registered.vue')
      },
      //修改密码
      {
        path: '/changePassword',
        name: 'ChangePassword',
        component: () => import('../views/pc/changePassword.vue')
      },
      //课程列表
      {
        path: '/courseList',
        name: 'courseList',
        component: () => import('../views/pc/courseList.vue')
      },
      //专题列表
      {
        path: '/specialList',
        name: 'specialList',
        component: () => import('../views/pc/specialList.vue')
      },
      //讲师列表
      {
        path: '/teacherList',
        name: 'teacherList',
        component: () => import('../views/pc/teacherList.vue')
      },
       //选课中心
      {
        path: '/courseSelection',
        name: 'CourseSelection',
        component: () => import('../views/pc/courseSelection.vue')
      },
      //系列课
      {
        path: '/seriesLessons',
        name: 'SeriesLessons',
        component: () => import('../views/pc/courseSelection.vue')
      },
      //系列课详情
      {
        path: '/seriesLessonsInfo/:id',
        name: 'SeriesLessonsInfo',
        component: () => import('../views/pc/seriesLessonsInfo.vue')
      },
      //直播课
      {
        path: '/liveList',
        name: 'LiveList',
        component: () => import('../views/pc/liveList.vue')
      },
      //搜索页
      {
        path: '/serch',
        name: 'Serch',
        component: () => import('../views/pc/serch.vue')
      },
      //讲师
      {
        path: '/lecturer',
        name: 'Lecturer',
        component: () => import('../views/pc/lecturer.vue')
      },
      //录播详情
      {
        path: '/recordedInfo/:id',
        name: 'RecordedInfo',
        component: () => import('../views/pc/recordedInfo.vue')
      },
      //录播详情-创新工作室培训分站
      {
        path: '/newRecordedInfo/:id',
        name: 'newRecordedInfo',
        component: () => import('../views/pc/newRecordedInfo.vue')
      },
      //确认订单
      {
        path: '/confirmOrder/:id',
        name: 'ConfirmOrder',
        component: () => import('../views/pc/confirmOrder.vue')
      },
      //收银台
      {
        path: '/cashier',
        name: 'Cashier',
        component: () => import('../views/pc/cashier.vue')
      },
      //支付宝支付页面 aliPay
      {
        path: '/aliPay',
        name: 'AliPay',
        component: () => import('../views/pc/aliPay.vue')
      },
      //讲师个人主页
      {
        path: '/lecturerHomepage/:id',
        name: 'LcturerHomepage',
        component: () => import('../views/pc/lecturerHomepage.vue')
      },
      //课程播放
      {
        path: '/coursePlay/:id',
        name: 'CoursePlay',
        component: () => import('../views/pc/coursePlay.vue')
      },
      //未登录 课程播放
      {
        path: '/noLogin_coursePlay/:id',
        name: 'noLogin_coursePlay',
        component: () => import('../views/pc/noLogin_coursePlay.vue')
      },
      //考试
      {
        path: '/text/:id/:type',
        name: 'Text',
        component: () => import('../views/pc/text.vue'),
        meta: {
          headerIsShow:true,
          footerIsShow:true,
          titleText:'考试' 
        }
       
      },
      //考试
      {
        path: '/textarea',
        name: 'Textarea',
        component: () => import('../views/pc/textarea.vue')
      },
      //公开考试列表
      {
        path: '/commonExam/list',
        name: 'commonExamList',
        component: () => import('../views/pc/commonExam/list.vue')
      },{
        path: '/commonExam/text/:id',
        name: 'commonExamText',
        component: () => import('../views/pc/commonExam/text.vue'),
        // meta: {
        //   headerIsShow:true,
        //   footerIsShow:true 
        // }
      },
      //活动文章列表
      {
        path: '/activeList/:id',
        name: 'ActiveList',
        component: () => import('../views/pc/activeList.vue')
      },
      //活动文章详情
      {
        path: '/activeInfo/:id',
        name: 'ActiveInfo',
        component: () => import('../views/pc/activeInfo.vue')
      },
      //个人中心userCenter
      {
        path: '/userCenter',
        name: 'UerCenter',
        component: () => import('../views/pc/userCenter/index.vue'),
        redirect:'/userCenter/material',
        children: [
          //个人资料
          {
            path: 'material',
            name: 'Material',
            component: () => import('../views/pc/userCenter/material.vue'),
          },
          //学习记录
          {
            path: 'learning',
            name: 'Learning',
            component: () => import('../views/pc/userCenter/learning.vue'),
          },
          //账号安全
          {
            path: 'accountSecurity',
            name: 'AccountSecurity',
            component: () => import('../views/pc/userCenter/accountSecurity.vue'),
          },
          //购买记录
          {
            path: 'purchaseRecords',
            name: 'PurchaseRecords',
            component: () => import('../views/pc/userCenter/purchaseRecords.vue'),
          },
          // //购买详情
          // {
          //   path: 'purchaseRecords/info/:id',
          //   name: 'PurchaseInfo',
          //   component: () => import('../views/pc/userCenter/purchaseInfo.vue'),
          // },
          //班级信息classInfo
          {
            path: 'classInfo',
            name: 'ClassInfo',
            component: () => import('../views/pc/userCenter/classInfo.vue'),
          },
          //我的考试exam_detail
          {
            path: 'myTest',
            name: 'MyTest',
            component: () => import('../views/pc/userCenter/myTest.vue'),
          },
          //我的考试详情
          {
            path: 'exam_detail',
            name: 'exam_detail',
            component: () => import('../views/pc/userCenter/exam_detail.vue'),
          },
          //消息通知
          // {
          //   path: 'message',
          //   name: 'Message',
          //   component: () => import('../views/pc/userCenter/message.vue'),
          // },
          //我的收藏
          {
            path: 'collection',
            name: 'Collection',
            component: () => import('../views/pc/userCenter/collection.vue'),
          },
          {
            path: 'commonExam',
            name: 'commonExam',
            component: () => import('../views/pc/userCenter/commonExam.vue'),
          },
          // 我的学分
          {
            path: 'mycredits',
            name: 'mycredits',
            component: () => import('../views/pc/userCenter/mycredits.vue'),
          },
          // 公开考试详情
          {
            path: 'commExamDetail',
            name: 'commExamDetail',
            component: () => import('../views/pc/userCenter/commExamDetail.vue'),
          }
        ]
      },

      // 劳动关系学院-个人中心 ldgxxyUserCenter
      {
        path:'/ldgxxyUserCenter',
        name:'ldgxxyUserCenter',
        component: () => import('../views/pc/ldgxxyUserCenter/index.vue'),
        redirect:'/ldgxxyUserCenter/learning',
        
        children: [
          // 学习记录
          {
            path: 'learning',
            name: 'Learning',
            component: () => import('../views/pc/ldgxxyUserCenter/learning.vue'),
            meta: {
              headerIsShow:true,
              footerIsShow:true 
            },
          },
          // 班级信息
          {
            path: 'classInfo',
            name: 'ClassInfo',
            component: () => import('../views/pc/ldgxxyUserCenter/classInfo.vue'),
            meta: {
              headerIsShow:true,
              footerIsShow:true 
            },
          },
          // 领取证书
          {
            path: 'certificate',
            name: 'certificate',
            component: () => import('../views/pc/ldgxxyUserCenter/certificate.vue'),
            meta: {
              headerIsShow:true,
              footerIsShow:true 
            },
          },
        ],
      },
      // 文本课程
      {
        path: '/textCourse/list',
        name: 'Text',
        component: () => import('../views/pc/textCourse/list.vue')
      },
      {
        path: '/textCourse/detail',
        name: 'detail',
        component: () => import('../views/pc/textCourse/detail.vue'),
      },
      {
        path: '/textCourse/playText',
        name: 'playText',
        component: () => import('../views/pc/textCourse/playText.vue'),
      },
      // 开放式班级
      {
        path: '/openClass',
        name: 'openClass',
        component: () => import('../views/pc/openClass.vue'),
      },
      // 预约表单
      {
        path: '/company_register',
        name: 'company_register',
        component: () => import('../views/pc/company_register.vue'),
      }
      //文本课程
      // {
      //   path: '/textCourse/list',
      //   name: 'textCourse/list',
      //   component: () => import('../views/pc/textCourse/list.vue'),
      //   // redirect:'/textCourse/list',
      //   children: [
      //     //个人资料
      //     {
      //       path: '/textCourse/detail',
      //       name: 'detail',
      //       component: () => import('../views/pc/textCourse/detail.vue'),
      //     },
      //     {
      //       path: '/textCourse/playText',
      //       name: 'playText',
      //       component: () => import('../views/pc/textCourse/playText.vue'),
      //     },
      //     {
      //       path: '/textCourse/readRecord',
      //       name: 'readRecord',
      //       component: () => import('../views/pc/textCourse/readRecord.vue'),
      //     },
      //   ]
      // },

    ]
  }
]

const routes_m = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'mHome',
    component: MHome,
    children: [
      //首页
      {
        path: '/',
        name: 'Index',
        // component: () => import('../m/indexQh.vue'),
        component: () => {
          if(window.location.host == 'localhost:8082'){
            return import('../m/indexQnz.vue')
          }
          if(
            window.location.host=='cxgzspx.kskstudy.com' || 
            window.location.host=='ymjg.kskstudy.com' || 
            window.location.host=='wxbz.kskstudy.com' ||
            window.location.host=='scglz.kskstudy.com'
          ){
            return import('../m/newIndex.vue')
          } else if(window.location.host=='qnzgjxy.kskstudy.com'){
            return import('../m/indexQnz.vue')
          } else if(window.location.host=='aqgl.kskstudy.com'){
            return import('../m/indexAqgl.vue')
          }else if(window.location.host=='qhgjxy.kskstudy.com' || window.location.host=='localhost:8080'){
            return import('../m/indexQh.vue')
          } else{
            return import('../m/index.vue')
          }
        },
      },
      //直播页面
      {
        path: '/live/:no/:live_id',
        name: 'Live',
        component: () => import(/* webpackChunkName: "about" */ '../m/live.vue')
      },
      //空白页面，用于获取url参数
      {
        path: '/gzLoginValidate',
        name: 'gzLoginValidate',
        component: () => import(/* webpackChunkName: "about" */ '../m/gzLoginValidate.vue')
      },
      //学员须知-劳模学院分站
      {
        path: '/studentNotice',
        name: 'studentNotice',
        component: () => import(/* webpackChunkName: "about" */ '../m/studentNotice.vue')
      },
        // 活动
        {
        path: '/activity/list',
        name: 'activity/list',
        component: () => import('../m/activity/list.vue'),
      },{
        path: '/activity/detail',
        name: 'activity/detail',
        component: () => import('../m/activity/detail.vue'),
      },{
        path: '/activity/signUp',
        name: 'activity/signUp',
        component: () => import('../m/activity/signUp.vue'),
      },
      //活动文章列表
      {
        path: '/activeList/:id/:name',
        name: 'ActiveList',
        component: () => import('../m/activeList.vue')
      },
      //活动文章详情
      {
        path: '/activeInfo/:id',
        name: 'ActiveInfo',
        component: () => import('../m/activeInfo.vue')
      },
      //搜索页
      {
        path: '/search',
        name: 'Search',
        component: () => import('../m/search.vue')
      },
      //录播详情
      {
        path: '/recordedInfo/:id',
        name: 'RecordedInfo',
        component: () => import('../m/recordedInfo.vue')
      },
      //录播详情-创新专题
      {
        path: '/newRecordedInfo/:id',
        name: 'newRecordedInfo',
        component: () => import('../m/newRecordedInfo.vue')
      },
      //未登录录播详情
      {
        path: '/noLogin_recordedInfo/:id',
        name: 'noLogin_recordedInfo',
        component: () => import('../m/noLogin_recordedInfo.vue')
      },
      //欢迎注册
      {
        path: '/welcomeRegistered',
        name: 'WelcomeRegistered',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../m/welcomeRegistered.vue')
      },
      //注册
      {
        path: '/bindPhone/:openid',
        name: 'bindPhone',
        component: () => import('../m/bindPhone.vue'),
      },
      //微信登录绑定手机号
      {
        path: '/registered/:type',
        name: 'Registered',
        component: () => import('../m/registered.vue')
      },
      //个人中心userCenter
      {
        path: '/userCenter',
        name: 'UerCenter',
        component: () => import('../m/userCenter/index.vue')
      },
      //个人资料
      {
        path: '/userCenter/material',
        name: 'Material',
        component: () => import('../m/userCenter/material.vue'),
      },
      //修改个人资料
      {
        path: '/userCenter/edit/:type',
        name: 'Edit',
        component: () => import('../m/userCenter/edit.vue'),
      },
      //我的班级
      {
        path: '/userCenter/myClass',
        name: 'MyClass',
        component: () => import('../m/userCenter/myClass.vue'),
      },
      //我的班级
      {
        path: '/userCenter/classInfo/:id/:type',
        name: 'ClassInfo',
        component: () => import('../m/userCenter/classInfo.vue'),
      },
      //考试详情
      {
        path: '/userCenter/exam',
        name: 'ClassInfo',
        component: () => import('../m/userCenter/exam.vue'),
      },
      //学习记录
      {
        path: '/userCenter/learning',
        name: 'Learning',
        component: () => import('../m/userCenter/learning.vue'),
      },
      //我的收藏
      {
        path: '/userCenter/collection',
        name: 'Collection',
        component: () => import('../m/userCenter/collection.vue'),
      },
      //修改密码
      {
        path: '/userCenter/changePassword',
        name: 'ChangePassword',
        component: () => import('../m/userCenter/changePassword.vue')
      },
      //我的公开考试
      {
        path: '/userCenter/commonExam',
        name: 'commonExam',
        component: () => import('../m/userCenter/commonExam.vue')
      },
      // 我的学分
      {
        path: '/userCenter/mycredits',
        name: '/userCenter/mycredits',
        component: () => import('../m/userCenter/mycredits.vue'),
      },
      //我的公开考试-考试记录
      {
        path: '/userCenter/commExamDetail',
        name: 'commExamDetail',
        component: () => import('../m/userCenter/commExamDetail.vue')
      },

       // 劳动关系学院-个人中心 ldgxxyUserCenter
       {
        path:'/ldgxxyUserCenter',
        name:'ldgxxyUserCenter',
        component: () => import('../m/ldgxxyUserCenter/index.vue'),
      },
      // 劳动关系学院-学习记录
      {
        path: '/ldgxxyUserCenter/learning',
        name: 'Learning',
        component: () => import('../m/ldgxxyUserCenter/learning.vue'),
      },
      //我的班级
      {
        path: '/ldgxxyUserCenter/myClass',
        name: 'MyClass',
        component: () => import('../m/ldgxxyUserCenter/myClass.vue'),
      },
      // 劳动关系学院-班级信息
      {
        path: '/ldgxxyUserCenter/classInfo/:id/:type',
        name: 'ClassInfo',
        component: () => import('../m/ldgxxyUserCenter/classInfo.vue'),
        meta: {
          headerIsShow:true,
          footerIsShow:true 
        },
      },
      // 劳动关系学院-领取证书
      {
        path: '/ldgxxyUserCenter/certificate',
        name: 'certificate',
        component: () => import('../m/ldgxxyUserCenter/certificate.vue'),
        meta: {
          headerIsShow:true,
          footerIsShow:true 
        },
      },


      //直播课
      {
        path: '/liveList',
        name: 'LiveList',
        component: () => import('../m/liveList.vue')
      },
      //确认订单
      {
        path: '/confirmOrder/:id',
        name: 'ConfirmOrder',
        component: () => import('../m/confirmOrder.vue')
      },
      //考试
      {
        path: '/text/:id/:type',
        name: 'Text',
        component: () => import('../m/text.vue')
      },
      //考试
      {
        path: '/textarea',
        name: 'textarea',
        component: () => import('../m/textarea.vue')
      },
      //课程分类
      {
        path: '/courseClassify',
        name: 'CourseClassify',
        component: () => import('../m/courseClassify.vue')
      },
      //课程列表
      {
        path: '/courseList',
        name: 'CourseList',
        component: () => import('../m/courseList.vue')
      },
      //课程列表
      {
        path: '/courseShowList',
        name: 'CourseList',
        component: () => import('../m/courseShowList.vue')
      },
      //购买记录
      {
        path: '/userCenter/purchaseRecords',
        name: 'PurchaseRecords',
        component: () => import('../m/userCenter/purchaseRecords.vue'),
      },
      //讲师个人主页
      {
        path: '/lecturerHomepage/:id',
        name: 'LcturerHomepage',
        component: () => import('../m/lecturerHomepage.vue')
      },
      //讲师
      {
        path: '/lecturer',
        name: 'Lecturer',
        component: () => import('../m/lecturer.vue')
      },
      //系列课
      {
        path: '/seriesLessons',
        name: 'SeriesLessons',
        component: () => import('../m/lecturer.vue')
      },
      //系列课详情
      {
        path: '/seriesLessonsInfo/:id',
        name: 'SeriesLessonsInfo',
        component: () => import('../m/seriesLessonsInfo.vue')
      },

      // 文本课程
      {
        path: '/textCourse/list',
        name: 'Text',
        component: () => import('../m/textCourse/list.vue')
      },
      {
        path: '/textCourse/detail',
        name: 'detail',
        component: () => import('../m/textCourse/detail.vue'),
      },
      {
        path: '/textCourse/playText',
        name: 'playText',
        component: () => import('../m/textCourse/playText.vue'),
      },   
      // 开放式班级
      {
        path: '/openClass',
        name: 'openClass',
        component: () => import('../m/openClass.vue'),
      },
      // 预约表单
      {
        path: '/company_register',
        name: 'company_register',
        component: () => import('../m/company_register.vue'),
      },
      //公开考试列表
      {
        path: '/commonExam/list',
        name: 'commonExamList',
        component: () => import('../m/commonExam/list.vue')
      },{
        path: '/commonExam/text/:id',
        name: 'commonExamText',
        component: () => import('../m/commonExam/text.vue')
      },
      // 提示页
      {
        path: '/promptPage',
        name: 'promptPage',
        component: () => import('../m/promptPage.vue'),
      },
    ]
  }
]

// window.onresize = function(){
// }

const router = new VueRouter({
  // 路由模式：hash(默认)，history模式
  mode: 'history',
  base: '/',
  routes: IsPC() ? routes_pc : routes_m
})

export default router
